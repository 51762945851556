/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import {
    MetaActionType,
} from './Meta.type';

export const updateEveryTime = [
    'title',
    'description',
    'keywords',
    'canonical_url',
    'robots',
    'status_code',
    'og:title',
    'og:description',
    'og:image',
    'og:url',
    'og:type',
    'product:retailer_part_no',
    'product:price:amount',
    'product:sale_price:amount',
    'product:original_price:amount',
    'product:category',
];

/** @namespace Satisfly/Store/Meta/Reducer/filterData */
export const filterData = (data) => {
    const updated = updateEveryTime.reduce((acc, key) => {
        acc[key] = data[key];

        return acc;
    }, {});

    return { ...data, ...updated };
};

/** @namespace Satisfly/Store/Meta/Reducer/getInitialState */
export const getInitialState = () => ({
    title: '',
    title_prefix: '',
    title_suffix: '',
    description: '',
    keywords: '',
    canonical_url: '',
    robots: '',
    status_code: '',
});

/** @namespace Satisfly/Store/Meta/Reducer/MetaReducer */
export const MetaReducer = (
    state = getInitialState(),
    action,
) => {
    const { payload = {}, type } = action;

    switch (type) {
    case MetaActionType.UPDATE_META:
        const filteredData = filterData(payload);

        return {
            ...state,
            ...filteredData,
        };

    default:
        return state;
    }
};

export default MetaReducer;
