/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer,
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import history from 'Util/History';

import {
    UrlRewritePageType,
} from './UrlRewrites.config';

/** @namespace Satisfly/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    static stateMapping = {
        category: UrlRewritePageType.CATEGORY,
        product: UrlRewritePageType.PRODUCT,
        page: UrlRewritePageType.CMS_PAGE,
        insporations: UrlRewritePageType.SF_INSPIRATIONS_INDEX,
        inspiration: UrlRewritePageType.SF_INSPIRATION,
    };

    getTypeSpecificProps() {
        const {
            actionName: {
                id: actionNameId,
                display_mode: preloadDisplayMode,
            } = {},
            isPrefetchValueUsed,
        } = window;
        const {
            urlRewrite: {
                id = isPrefetchValueUsed ? actionNameId : undefined,
                sku,
                display_mode,
                sort_by,
            },
        } = this.props;

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
        case UrlRewritePageType.PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                // TODO: history.state.state looks like undefined all the time.
                const product = history?.location?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;
                    window.actionName.sku = historySKU;

                    return { productSKU: historySKU, id };
                }

                window.actionName.sku = sku;

                return {};
            }

            return { productSKU: sku, id };
        case UrlRewritePageType.CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case UrlRewritePageType.CATEGORY:
            /**
             * In case we are not yet sure what category ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                // TODO: history.state.state looks like undefined all the time.
                if (history) {
                    const {
                        location: {
                            state: {
                                category,
                                displayMode,
                            } = {},
                        } = {},
                    } = history;

                    if (category && category !== true) {
                        return {
                            categoryIds: category,
                            displayMode: isPrefetchValueUsed ? preloadDisplayMode : displayMode,
                            sort_by,
                        };
                    }
                }

                return {};
            }

            return {
                categoryIds: isPrefetchValueUsed && this.initialUrl === location.pathname ? window.actionName.id : id,
                displayMode: isPrefetchValueUsed ? preloadDisplayMode : display_mode,
                sort_by,
            };
        case UrlRewritePageType.SF_INSPIRATIONS_INDEX:
            return {};
        case UrlRewritePageType.SF_INSPIRATION:
            return { inspirationId: id };
        case UrlRewritePageType.NOTFOUND:
        default:
            return {};
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
