/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Satisfly/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    'og:title': state.MetaReducer['og:title'],
    'og:description': state.MetaReducer['og:description'],
    'og:image': state.MetaReducer['og:image'],
    'og:url': state.MetaReducer['og:url'],
    'og:type': state.MetaReducer['og:type'],
    'product:retailer_part_no': state.MetaReducer['product:retailer_part_no'],
    'product:price:amount': state.MetaReducer['product:price:amount'],
    'product:sale_price:amount': state.MetaReducer['product:sale_price:amount'],
    'product:original_price:amount': state.MetaReducer['product:original_price:amount'],
    'product:category': state.MetaReducer['product:category'],
    locale: state.ConfigReducer.locale,
    siteName: state.ConfigReducer.default_title,
});

/** @namespace Satisfly/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Satisfly/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static defaultProps = {
        ...super.defaultProps,
        'og:title': '',
        'og:description': '',
        'og:image': '',
        'og:url': '',
        'og:type': 'website',
    };

    containerProps() {
        const {
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
        } = this.props;

        return {
            metadata: this._getMetadata(),
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
            ogmetadata: this._getOGMetadata(),
        };
    }

    _getOGMetadata = () => {
        const meta = {
            'og:type': this.props['og:type'],
            'og:url': this.props['og:url'],
            'og:title': this.props['og:title'],
            'og:description': this.props['og:description'],
            'og:image': this.props['og:image'],
            'og:locale': this.props.locale || 'pl_PL',
            'og:site_name': this.props.siteName,
            'product:retailer_part_no': this.props['product:retailer_part_no'],
            'product:price:amount': this.props['product:price:amount'],
            'product:sale_price:amount': this.props['product:sale_price:amount'],
            'product:original_price:amount': this.props['product:original_price:amount'],
            'product:category': this.props['product:category'],
        };

        return this._generateMetaFromMetadata(meta, 'property');
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
