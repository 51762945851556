/* eslint-disable no-magic-numbers */
/* eslint-disable no-undef */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/scandipwa
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** @namespace Satisfly/Component/Synerise/Component/Synerise */
export function Synerise() {
    const currentLocation = useLocation();
    useEffect(() => {
        setTimeout(() => {
            if (typeof SR !== 'undefined' && SR?.event?.pageVisit) {
                SR.event.pageVisit().then(
                    /** @namespace Satisfly/Component/Synerise/Component/Synerise/useEffect/setTimeout/pageVisit/then */
                    () => {
                        SR.dynamicContent.get();
                    }
                );
            }
        }, 2000);
    }, [currentLocation]);

    return null;
}

export default Synerise;
